<template>
  <div class="login">
    <div class="login-card">
      <!-- <h2>Login</h2> -->
      <img src="../assets/logo.png" alt="toktok admin" class="login-logo" />
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        ref="form"
        @submit.native.prevent="onSubmit"
      >
        <el-form-item prop="username" label="E-mail:">
          <el-input
            v-model="model.username"
            placeholder="Username"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="Password:">
          <el-input
            v-model="model.password"
            placeholder="Password"
            type="password"
            @keyup.enter.native="onSubmit()"
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            @keyup.enter="onSubmit()"
            >Нэвтрэх</el-button
          >
        </el-form-item>
        <!-- <a class="forgot-password" href="https://oxfordinformatics.com/">Forgot password ?</a> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { signIn } from "@/utils/auth.js";
export default {
  name: "login",
  data() {
    return {
      validCredentials: {
        username: "lightscope",
        password: "lightscope"
      },
      model: {
        username: "",
        password: ""
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: "Username is required",
            trigger: "blur"
          },
          {
            min: 4,
            message: "Username length should be at least 5 characters",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "Password is required", trigger: "blur" },
          {
            min: 5,
            message: "Password length should be at least 5 characters",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.load = true;
      signIn(this.model.username, this.model.password);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: top;
  background-color: #0d2945;
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 100px;
}
.login-logo {
  padding-bottom: 50px;
}
.login-card {
  background-color: #0d2945;
  border: none;
  /* padding-bottom: 50px; */
}

.login-button {
  width: 100%;
  margin-top: 20px;
}
.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
  color: #fff;
}
</style>
<style lang="scss">
$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.login .el-input input {
  padding-left: 35px;
}
.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}
h2 {
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}
a {
  color: $teal;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
  width: 340px;
  display: flex;
  justify-content: center;
}
.el-form-item__label {
  color: #ffffff;
}
</style>
